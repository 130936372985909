// @ts-check
/**
 * @typedef {{ title: string, link: string, target?: string, spacer: string, area: string }} NavigationResult
 */

class NavigationError extends Error {}

/**
 * Calls the API to receive the user that is currently signed in
 * @return {Promise<[NavigationResult, NavigationError]>}
 */
export const getNavigation = () => {
  return new Promise(resolve => {
    fetch(`https://www.checkin-berufswelt.net/index.php?type=9205547`, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new NavigationError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new NavigationError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(navigation => {
        // TODO: make sure that the result is in fact a UserResult object
        resolve([navigation, undefined]);
      })
      .catch(e => {
        console.error(e); // we don't wanna swallow the error
        resolve([
          undefined,
          new NavigationError(
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
          )
        ]);
      });
  });
};
