import React from 'react';
import { WindowSizeContext } from '../context/WindowSizeContext';

/**
 * Usage:
 * ```
 * const HiddenOnMobile = viewportHoc('desktop', 'tablet');
 *
 * <HiddenOnMobile>hello desktop and tablet</HiddenOnMobile>
 * ```
 * @param  {string[]} viewportsOnly
 */
const viewportHoc = (...viewportsOnly) => {
  return props => (
    <WindowSizeContext.Consumer>
      {({ viewport }) => {
        if (viewportsOnly.includes(viewport)) {
          return props.children;
        }
      }}
    </WindowSizeContext.Consumer>
  );
};
export { viewportHoc };
