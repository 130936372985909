import React from 'react';
import style from './footer.module.scss';

export const Footer = ({ fixed = true }) => {
  let className = style.footer;
  if (!fixed) {
    className += ' ' + style.notFixed;
  }

  return (
    <footer className={className}>
      <div className={style.left}>
        <a
          href="https://www.checkin-berufswelt.net/impressum-datenschutz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Impressum und Datenschutz
        </a>
        {/* </a>{' '}
        |{' '}
        <a
          href=" mailto:info@thueringenpackts.de?subject=R%C3%BCckmeldung%20Th%C3%BCringen%20packt's"
          target="_blank"
          style={{ fontWeight: 'bold', fontSize: '18px' }}
        >
          Kontakt
        </a> */}
      </div>
      <div className={style.right}>
        {new Date().getFullYear()} © PLAN:KOOPERATIV &amp; webschuppen
      </div>
    </footer>
  );
};
