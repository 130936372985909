import { Link } from 'gatsby';
import React from 'react';
import style from './header.module.scss';
import { Container } from './container';
import { Button } from './button';
import { UserContext } from '../context/UserContext';
import { API_ENDPOINT } from '../api';
import logo from '../images/CI_Logo_4c_2018_white.png';
import { getNavigation } from '../api/navigation';
import NavigationStyle from '../components/navigation.module.scss';

const Menu = props => {
  return (
    <>
      {props && props.items && props.items.length > 0 && (
        <ul className={props.className ? props.className : ''}>
          {props.items.map(item => (
            <li
              key={`nav-${item.title}`}
              className={
                item.title === 'Interaktive Karte'
                  ? NavigationStyle.navItemActive
                  : NavigationStyle.navItem
              }
            >
              <a href={item.link} target={item.target ? item.target : '_self'}>
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mainNav: null, legalNav: null, error: null };
  }
  componentDidMount() {
    getNavigation()
      .then(([navigation, err]) => {
        if (err) {
          return this.setState({ mainNav: null, legalNav: null, error: err.message });
        }
        const noSpacer = navigation.filter(function(navItem) {
          return navItem.spacer <= 0;
        });
        const mainNav = noSpacer.filter(function(navItem) {
          return navItem.area === 'main';
        });
        const legalNav = noSpacer.filter(function(navItem) {
          return navItem.area === 'legal';
        });
        this.setState({ mainNav: mainNav, legalNav: legalNav, error: null });
      })
      .catch(e => {
        console.error(e);
        return this.setState({
          mainNav: null,
          legalNav: null,
          error: 'Something unexpected happened!'
        });
      });
  }
  render() {
    return (
      <header className={style.header}>
        <Container style={{ display: 'flex', flexDirection: 'row', maxWidth: 1333 }}>
          <a href="https://www.checkin-berufswelt.net/" className={NavigationStyle.logo}>
            <img src={logo} alt="Check-In" />
          </a>
          <Menu items={this.state.mainNav} className={NavigationStyle.mainNav} />
          <Menu items={this.state.legalNav} className={NavigationStyle.legalNav} />
          <div className={style.pullRight}>
            <UserContext.Consumer>
              {user =>
                user.loggedIn &&
                user.roles.includes('ROLE_ADMIN') && (
                  <a href={`${API_ENDPOINT}/api/export/csv`} download>
                    <Button modifier={'light'}>Export CSV</Button>
                  </a>
                )
              }
            </UserContext.Consumer>
          </div>
        </Container>
      </header>
    );
  }
}

export default Header;
